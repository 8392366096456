//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-452:_4484,_3052,_244,_1096,_3500,_3268,_1716,_3292;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-452')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-452', "_4484,_3052,_244,_1096,_3500,_3268,_1716,_3292");
        }
      }catch (ex) {
        console.error(ex);
      }